import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Nav from "./Nav";
import SigmaReport from "./SigmaReport";
import EditUserForm from "./EditUserForm";
import { parseJwt } from "../util/parseJwt";
import "./Homepage.css";
import SignupForm from "./SignupForm";
import UploadImageForm from "./UploadImageForm";
import Menu from "./Menu";
import HomeButton from "../assets/images/home.svg";

const Homepage = ({ isLoggedIn, handleLogout }) => {
  const [embedUrls, setEmbedUrls] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [allProperties, setAllProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showSignupForm, setShowSignupForm] = useState(false);
  const [showRemoveForm, setShowRemoveForm] = useState(false);
  const [showUploadImageForm, setShowUploadImageForm] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [currentReport, setCurrentReport] = useState("Dashboard");
  const [menuOpen, setMenuOpen] = useState(false);
  const [showEditUserForm, setShowEditUserForm] = useState(false);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [ourCustomers, setOurCustomers] = useState([]);
  const [customerNames, setCustomerNames] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Fetch user details and properties on login
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decoded = parseJwt(token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
          console.error("Token expired.");
          handleLogout();
          return;
        }

        setUserDetails(decoded);
        const isAdmin =
          decoded.email.endsWith("@totalcomfortsolutions.com") ||
          decoded.email.endsWith("@dynamiq.com");
        setIsAdmin(isAdmin);

        if (!isAdmin) {
          // Fetch both customer names and property names by email for non-admin users
          fetchCustomersByEmail(decoded.email, token).then((customerNames) => {
            if (customerNames.length > 0) {
              setCustomerNames(customerNames);
              fetchPropertyIdsByEmail(decoded.email, token).then(
                (propertyNames) => {
                  // Pass customerNames, propertyNames, decoded, and initialFilterUrls
                  fetchFilteredEmbedUrl(
                    propertyNames,
                    customerNames,
                    decoded,
                    initialFilterUrls
                  );
                  setAllProperties(propertyNames);
                  setFilteredProperties(propertyNames);
                }
              );
            } else {
              console.error("No customer names found for the user.");
            }
          });
        } else {
          fetchAdminCustomers(token);
        }
      } catch (error) {
        console.error("Failed to parse JWT:", error);
        handleLogout();
      }
    } else {
      console.error("No token found, user is not authorized.");
    }
  }, []);

  // Handle report switching and embedding filtered URLs
  useEffect(() => {
    const fetchEmbedUrlsForReport = async () => {
      const selectedReport = reports.find((r) => r.name === currentReport);
      if (selectedReport && selectedReport.embedded) {
        const propertiesToUse = filteredProperties.length
          ? filteredProperties
          : allProperties;

        await fetchFilteredEmbedUrl(
          propertiesToUse, // selectedProperties
          customerNames, // selectedCustomers (array of customer names)
          userDetails, // userDetails (JWT object or user details)
          selectedReport.embedded, // urls (URLs array for embedding)
          selectedCustomer // Pass the selected customer
        );
      } else {
        console.error("Report not found or missing embed URLs.");
        setEmbedUrls([]);
      }
    };

    if (currentReport && userDetails.email) {
      fetchEmbedUrlsForReport();
    }
  }, [
    currentReport,
    userDetails.email,
    filteredProperties,
    allProperties,
    selectedCustomer,
  ]);

  useEffect(() => {
    // Fetch customers from the API
    const fetchOurCustomers = async () => {
      try {
        const response = await fetch("/api/users/");
        const data = await response.json();
        setOurCustomers(data.users || []); // Assuming the API response is in the format { ourcustomers: [...] }
      } catch (error) {
        console.error("Error fetching our customers:", error);
      }
    };

    fetchOurCustomers(); // Call the API when the component mounts
  }, []);

  const fetchPropertyIdsByEmail = async (email, token) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/users/getproperty/${email}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Access the user array from the response
      const user = response.data.user;

      // Check if user exists and extract BUILDOPS_PROPERTY_IDS from the first user in the array
      if (user && user.length > 0) {
        const propertyIds = user[0].BUILDOPS_PROPERTY_IDS;

        // Ensure propertyIds exists before proceeding
        if (propertyIds && propertyIds.length > 0) {
          fetchUserProperties(user[0], { email }, token); // <-- Make sure this function is called correctly
          return user[0].PROPERTIES; // Return property names correctly
        } else {
          console.error("No property names found for the user.");
          return [];
        }
      } else {
        console.error("No user data found in the response.");
        return [];
      }
    } catch (error) {
      console.error("Failed to fetch BUILDOPS_PROPERTY_IDS:", error);
      return [];
    }
  };

  const fetchCustomersByEmail = async (email, token) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/users/usercustomerbyemail/${email}`,
        {
          headers: { Authorization: `Bearer ${token}` }, // Pass the token for authorization
        }
      );

      const customerNames = response.data.customers;

      if (!customerNames || customerNames.length === 0) {
        console.error("No customers found for this email.");
        return [];
      }

      // Ensure a flat array of strings
      return customerNames.map((customer) => customer.CUSTOMER_NAME).flat();
    } catch (error) {
      console.error("Error fetching customers by email:", error);
      return [];
    }
  };

  const fetchUserProperties = async (user, decoded, token) => {
    try {
      const propertyNames = user.PROPERTIES; // Extract the property names from the user object
      if (!propertyNames || !propertyNames.length) {
        console.error("No property names found for the user.");
        return;
      }

      // Fetch customer names using the same method as above
      const customerNames = await fetchCustomersByEmail(decoded.email, token);

      // Pass both customer names and property names to fetchFilteredEmbedUrl
      fetchFilteredEmbedUrl(
        propertyNames,
        customerNames,
        decoded,
        initialFilterUrls
      );

      // Update the UI
      setAllProperties(propertyNames); // Store property names in the state
      setFilteredProperties(propertyNames);
    } catch (error) {
      console.error("Failed to handle property IDs:", error);
    }
  };

  const fetchAdminCustomers = async (token) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/users/`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const nonAdminCustomers = response.data.users.filter(
        (customer) =>
          !customer.EMAIL.endsWith("@totalcomfortsolutions.com") &&
          !customer.EMAIL.endsWith("@dynamiq.com")
      );
      setCustomers(nonAdminCustomers);
    } catch (error) {
      console.error("Failed to fetch customers:", error);
    }
  };

  const fetchFilteredEmbedUrl = async (
    selectedProperties = [], // Property names array
    selectedCustomers = [], // Customer names array
    userDetail, // Decoded JWT or user details object
    urls, // URLs array for embedding
    customer // Selected customer object
  ) => {
    /*console.log("fetchFilteredEmbedUrl called with:", {
      selectedProperties,
      selectedCustomers,
      userDetail,
      urls,
      customer,
    });
*/
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, authorization denied.");
      setEmbedUrls([]);
      return;
    }

    const userId = userDetail.userId || userDetail.BUILDOPS_CUSTOMER_ID;
    let email;
    if (isAdmin && customer) {
      email = customer.EMAIL;
    } else {
      email = userDetail.email || userDetail.EMAIL;
    }

    // Validate selectedCustomers
    const validSelectedCustomers =
      selectedCustomers.length > 0 ? selectedCustomers : [email];

    try {
      const payloads = urls.map((url) => ({
        userId,
        email,
        _url: url,
        properties: selectedProperties, // Pass property names here
        customers: validSelectedCustomers, // Pass customer names here
        selectedReport: currentReport,
        userDetails: userDetail.email || userDetail.EMAIL,
      }));

      const requests = payloads.map((payload) =>
        axios.post(
          `${process.env.REACT_APP_API_URL}/api/sigma/filtered`,
          payload,
          { headers: { Authorization: `Bearer ${token}` } }
        )
      );

      const responses = await Promise.all(requests);
      const filteredUrls = responses.map((response) => response.data.url);
      setEmbedUrls(filteredUrls);
    } catch (error) {
      console.error("Failed to fetch filtered embed URLs:", error);
      setEmbedUrls([]);
    }
  };

  const handleReportSelect = (report) => {
    setCurrentReport(report.name);
  };

  const handleViewAsCustomer = async (customerId) => {
    const customer = ourCustomers.find((c) => c.EMAIL === customerId);
    if (!customerId) {
      setSelectedCustomer(null);
      setAllProperties([]);
      setFilteredProperties([]);
      setEmbedUrls([]);
      return;
    }

    setSelectedCustomer(customer);
    const newCustomerId = customer.BUILDOPS_CUSTOMER_ID[0];

    const token = localStorage.getItem("token");

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/sigma/buildings/${newCustomerId}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const propertyNames = response.data.property_names;

      if (propertyNames && propertyNames.length > 0) {
        setAllProperties(propertyNames);
        setFilteredProperties(propertyNames);

        // Fetch customer names using the same method as above
        const customerNames = await fetchCustomersByEmail(
          customer.EMAIL,
          token
        );

        // Pass both customer names and property names to the function
        fetchFilteredEmbedUrl(
          propertyNames,
          customerNames,
          userDetails,
          initialFilterUrls,
          customer
        );
      } else {
        console.error("No properties found for the selected customer.");
      }
    } catch (error) {
      console.error("Failed to fetch customer properties:", error);
    }
  };

  const reports = [
    {
      id: 1,
      name: "Dashboard",
      group: "General",
      embedded: [
        "https://app.sigmacomputing.com/embed/1-35aUvxAlRNiXZwsV5hNg2T",
      ],
    },
    {
      id: 2,
      name: "Quotes",
      group: "General",
      embedded: [
        "https://app.sigmacomputing.com/embed/1-1Z71hnA3movxkJim6zBKpL",
      ],
    },
    {
      id: 3,
      name: "Work Orders",
      group: "Work History",
      embedded: [
        "https://app.sigmacomputing.com/embed/1-4UJL3UoK0JWqt9hcBXKvtu",
      ],
    },
    {
      id: 4,
      name: "Maintenance",
      group: "Work History",
      embedded: [
        "https://app.sigmacomputing.com/embed/1-1PhJCSwMmReVy5PsniW4Fb",
      ],
    },
    {
      id: 5,
      name: "Capital Projects",
      group: "Work History",
      embedded: [
        "https://app.sigmacomputing.com/embed/1-25ajUPjBcHse1CuokroeZO",
      ],
    },
    {
      id: 6,
      name: "Assets",
      group: "General",
      embedded: [
        "https://app.sigmacomputing.com/embed/1-1DtrFU4q5mOMvLJ2KUAS9C",
      ],
    },
    {
      id: 7,
      name: "Invoicing",
      group: "General",
      embedded: [
        "https://app.sigmacomputing.com/embed/1-2jXHb5uKlbCHnvzz1eiDnr",
      ],
    },
    {
      id: 9,
      name: "Analytics",
      group: "General",
      embedded: [
        "https://app.sigmacomputing.com/embed/1-2V0ukgjJTjOrUnCLMC5FMa",
      ],
    },
    {
      id: 8,
      name: "Utilities",
      group: "General",
      embedded: [
        "https://app.sigmacomputing.com/embed/1-6JQBizU7vDZ9xOn5EWxulf",
      ],
    },
  ];

  const initialFilterUrls = reports[0].embedded;

  function toProperCase(text) {
    if (typeof text !== "string") {
      return "";
    }
    return text.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  }

  const filteredCustomerList = ourCustomers
    .filter(
      (customer) =>
        customer.EMAIL &&
        customer.EMAIL.trim() !== "" &&
        !/(@dynamiq\.com|@dynamiq\.net|@totalcomfortsolutions\.com)$/i.test(
          customer.EMAIL
        ) &&
        customer.EMAIL.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) =>
      String(a.CUSTOMER_NAME || "").localeCompare(String(b.CUSTOMER_NAME || ""))
    );

  const handleSelectCustomer = (email) => {
    setSearchTerm(email); // Set the selected customer's email in the input
    setIsDropdownOpen(false); // Close the dropdown
    handleViewAsCustomer(email); // Trigger your existing function
  };

  const handleClearSearchTerm = () => {
    setSearchTerm("");
  };

  const renderContent = () => {
    // If no embed URLs are available, display a loading message
    if (!embedUrls.length) {
      return <p className="report-load"></p>;
    }

    return (
      <div className="reports-grid">
        {embedUrls.map((url, index) => (
          <SigmaReport key={index} embedUrl={url} />
        ))}
      </div>
    );
  };

  return (
    <div className="homepage-wrapper">
      <div className={`report-list-container`}>
        <Nav
          reports={reports}
          handleReportSelect={handleReportSelect}
          isLoggedIn={isLoggedIn}
          handleLogout={handleLogout}
        />
      </div>

      <div className="homepage-content">
        <div className="header">
          <div className="left-buttons-container">
            <button
              onClick={() => setCurrentReport("Dashboard")}
              className="home-button"
            >
              <img src={HomeButton} alt="Home" />
            </button>

            {currentReport && (
              <span className="selected-report-name">{currentReport}</span>
            )}
          </div>

          {/* Admin controls */}
          {isAdmin && (
            <div className="admin-controls-container">
              <div className={`admin-controls`}>
                <span className="admin-title">Admin</span>
                <button
                  className="add-user-button"
                  onClick={() => setShowSignupForm(true)}
                >
                  Add User
                </button>

                <div className="custom-dropdown" ref={dropdownRef}>
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <input
                      type="text"
                      placeholder="Select User View"
                      value={searchTerm}
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="customer-search-input"
                      style={{ paddingRight: "30px" }} // Add padding for the clear button
                    />
                    {searchTerm && (
                      <button
                        onClick={handleClearSearchTerm}
                        style={{
                          position: "absolute",
                          right: "10px",
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                          fontSize: "16px",
                          color: "#888",
                          padding: "0", // Remove any default button padding
                        }}
                      >
                        ✕
                      </button>
                    )}
                  </div>
                  {isDropdownOpen && (
                    <div className="dropdown-options">
                      {filteredCustomerList.length > 0 ? (
                        filteredCustomerList.map((customer) => (
                          <div
                            key={customer.EMAIL}
                            className="dropdown-option"
                            onClick={() => handleSelectCustomer(customer.EMAIL)}
                          >
                            <strong>{customer.EMAIL}</strong>{" "}
                            {` (${customer.CUSTOMER_NAME})`}
                          </div>
                        ))
                      ) : (
                        <div className="dropdown-option" disabled>
                          No customers available
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <button
                  className="add-user-button"
                  onClick={() => setShowEditUserForm(true)}
                >
                  Edit User
                </button>
                {showEditUserForm && (
                  <EditUserForm
                    customers={customers}
                    onClose={() => setShowEditUserForm(false)}
                  />
                )}
              </div>
            </div>
          )}
          <div className="controls-container">
            <p className="customer-name" onClick={() => setMenuOpen(!menuOpen)}>
              {Array.isArray(customerNames) && customerNames.length > 0
                ? customerNames.flat().map(toProperCase).join(", ") // Flatten the array and map over it
                : `${isAdmin ? "Admin" : "No customer on account"}`}{" "}
              |
            </p>

            <Menu
              handleLogout={handleLogout}
              username={toProperCase(userDetails.name)}
              externalToggle={menuOpen}
            />
          </div>
        </div>

        {/* Render signup form if showSignupForm is true */}
        {showSignupForm && (
          <SignupForm onClose={() => setShowSignupForm(false)} />
        )}

        {renderContent()}
      </div>
    </div>
  );
};

export default Homepage;
