import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./SignupForm.css";

const LoadingSpinner = () => <div className="loading-spinner">Loading...</div>;

const SignupForm = ({ onClose }) => {
  // User Information States
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  // Customers States
  const [availableCustomers, setAvailableCustomers] = useState([]); // All available customers
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]); // IDs of selected customers
  const [selectedCustomers, setSelectedCustomers] = useState([]); // Objects of selected customers

  // Properties States
  const [availableProperties, setAvailableProperties] = useState([]); // Aggregated properties from selected customers
  const [selectedProperties, setSelectedProperties] = useState([]); // IDs of selected properties

  // UI States
  const [searchText, setSearchText] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isSignupSuccessful, setIsSignupSuccessful] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const dropdownRef = useRef(null);

  // Loading states
  const [isCustomersLoading, setIsCustomersLoading] = useState(false);
  const [isPropertiesLoading, setIsPropertiesLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  // Fetch all customers on component mount
  useEffect(() => {
    const fetchCustomers = async () => {
      setIsCustomersLoading(true);
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/sigma/customers`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (response.data.customer_names && response.data.customer_ids) {
          setAvailableCustomers(
            response.data.customer_names.map((customer, index) => ({
              id: response.data.customer_ids[index],
              name: customer,
            }))
          );
        }
      } catch (error) {
        setError("Failed to fetch customers. Please try again.");
      } finally {
        setIsCustomersLoading(false);
      }
    };

    fetchCustomers();
  }, []);

  // Handle customer selection/deselection
  const handleCustomerToggle = (id) => {
    setSelectedCustomerIds((prevSelected) => {
      if (prevSelected.includes(id)) {
        // Deselect the customer
        return prevSelected.filter((customerId) => customerId !== id);
      } else {
        // Select the customer
        return [...prevSelected, id];
      }
    });
  };

  // Update selectedCustomers based on selectedCustomerIds
  useEffect(() => {
    const selected = availableCustomers.filter((customer) =>
      selectedCustomerIds.includes(customer.id)
    );
    setSelectedCustomers(selected);
  }, [selectedCustomerIds, availableCustomers]);

  // Handle "Select All" customers
  const handleSelectAllChange = () => {
    if (selectedProperties.length === availableProperties.length) {
      // Deselect all
      setSelectedProperties([]);
    } else {
      // Select all
      const allPropertyIds = availableProperties.map((property) => property.id);
      setSelectedProperties(allPropertyIds);
    }
  };

  // Fetch properties whenever selectedCustomerIds change
  useEffect(() => {
    const fetchProperties = async () => {
      if (selectedCustomerIds.length === 0) {
        setAvailableProperties([]);
        setSelectedProperties([]);
        return;
      }

      setIsPropertiesLoading(true);
      try {
        const token = localStorage.getItem("token");
        const propertyPromises = selectedCustomerIds.map((id) =>
          axios.get(
            `${process.env.REACT_APP_API_URL}/api/sigma/buildings/${id}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
        );

        const responses = await Promise.all(propertyPromises);
        let allProperties = [];

        responses.forEach((response) => {
          if (
            response.data.property_names &&
            response.data.property_ids &&
            response.data.property_type
          ) {
            const properties = response.data.property_names.map(
              (name, index) => ({
                id: response.data.property_ids[index],
                name,
                property_type: response.data.property_type[index],
              })
            );
            allProperties = [...allProperties, ...properties];
          }
        });

        const uniqueProperties = Array.from(
          new Map(allProperties.map((item) => [item.id, item])).values()
        );

        setAvailableProperties(uniqueProperties);
      } catch (error) {
        setError("Failed to fetch properties. Please try again.");
        setAvailableProperties([]);
      } finally {
        setIsPropertiesLoading(false);
      }
    };

    fetchProperties();
  }, [selectedCustomerIds]);

  // Handle property selection/deselection
  const handlePropertyToggle = (property) => {
    setSelectedProperties((prevSelected) => {
      if (prevSelected.includes(property.id)) {
        // Deselect the property
        return prevSelected.filter((id) => id !== property.id);
      } else {
        // Select the property
        return [...prevSelected, property.id];
      }
    });
  };

  // Handle "Select All" properties
  const handleSelectAllProperties = () => {
    if (selectedProperties.length === availableProperties.length) {
      // Deselect all
      setSelectedProperties([]);
    } else {
      // Select all
      const allPropertyIds = availableProperties.map((property) => property.id);
      setSelectedProperties(allPropertyIds);
    }
  };

  const isAllPropertiesSelected =
    selectedProperties.length === availableProperties.length &&
    availableProperties.length > 0;

  // Generate a temporary password
  const generateTempPassword = (length = 12) => {
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+[]{}|;:,.<>?";
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return password;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!name || !email) {
      setError("Name and Email are required.");
      return;
    }

    const token = localStorage.getItem("token");
    const tempPassword = generateTempPassword();
    const buildops_customer_ids = selectedCustomerIds;

    // Prepare selected properties with both ID and name
    const selectedPropertyObjects = availableProperties
      .filter((property) => selectedProperties.includes(property.id))
      .map((property) => ({
        id: property.id,
        name: property.name,
      }));

    const data = {
      name: name.toLowerCase(),
      email: email.toLowerCase(),
      password: tempPassword,
      customers: selectedCustomers.map((customer) => ({
        id: customer.id,
        name: customer.name,
      })),
      buildops_property_ids: selectedPropertyObjects.map(
        (property) => property.id
      ),
      properties: selectedPropertyObjects.map((property) => property.name),
      buildops_customer_ids,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/users/signup`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setSuccess("User created successfully!");
      setError("");
      setIsSignupSuccessful(true);
      setShowSuccessPopup(true);
    } catch (err) {
      if (err.response) {
        if (
          err.response.status === 422 &&
          err.response.data.error === "User already exists."
        ) {
          setError("User with this email already exists.");
        } else {
          setError("Failed to create user. Please try again.");
        }
      } else {
        setError("Failed to create user. Please try again.");
      }
      console.error("Signup error:", err);
    }
  };

  // Filter customers based on search text
  const filteredCustomers = availableCustomers.filter((customer) =>
    customer.name.toLowerCase().includes(searchText.toLowerCase())
  );

  // Filter customers based on search text
  const filteredProperties = availableProperties.filter((property) =>
    property.name.toLowerCase().includes(searchText.toLowerCase())
  );

  // Handle clicking outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Determine if customer and properties sections should be hidden
  const shouldHideCustomerAndProperties =
    email.endsWith("@totalcomfortsolutions.com") ||
    email.endsWith("@dynamiq.com");

  return (
    <div className="overlay">
      <div className="signup-form-modal">
        <h2>Create New User</h2>
        {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p>}
        <form onSubmit={handleSubmit}>
          {/* Name Input */}
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />

          {/* Email Input */}
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          {/* Conditionally render Customer and Properties selection */}
          {!shouldHideCustomerAndProperties && (
            <>
              {/* Customers Section */}
              <div className="properties-section">
                <h3>Select Customers</h3>

                <input
                  type="text"
                  placeholder="Search customers..."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />

                {isCustomersLoading ? (
                  <LoadingSpinner />
                ) : (
                  <div className="properties-checkboxes">
                    {filteredCustomers.length > 0 ? (
                      filteredCustomers
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((customer) => (
                          <label
                            key={customer.id}
                            className="property-checkbox"
                          >
                            <input
                              type="checkbox"
                              checked={selectedCustomerIds.includes(
                                customer.id
                              )}
                              onChange={() => handleCustomerToggle(customer.id)}
                            />
                            {customer.name}
                          </label>
                        ))
                    ) : (
                      <p>No customers available.</p>
                    )}
                  </div>
                )}
              </div>

              {/* Display Selected Customers */}
              {selectedCustomers.length > 0 && (
                <div className="selected-items">
                  <h4>Selected Customers:</h4>
                  <ul>
                    {selectedCustomers.map((customer) => (
                      <li key={customer.id}>{customer.name}</li>
                    ))}
                  </ul>
                </div>
              )}

              {/* Properties Section */}
              <div className="properties-section">
                <h3>Select Properties</h3>
                {isPropertiesLoading ? (
                  <LoadingSpinner />
                ) : availableProperties.length > 0 ? (
                  <div className="properties-checkboxes">
                    {/* Select All Checkbox */}
                    <label className="property-checkbox">
                      <input
                        type="checkbox"
                        checked={
                          selectedProperties.length ===
                            filteredProperties.length &&
                          filteredProperties.length > 0
                        }
                        onChange={handleSelectAllChange} // Handles select all or deselect all logic
                      />
                      {selectedProperties.length === filteredCustomers.length
                        ? "Select None" // If all are selected, show this label
                        : "Select All"}{" "}
                    </label>
                    {availableProperties.map((property) => (
                      <label key={property.id} className="property-checkbox">
                        <input
                          type="checkbox"
                          checked={selectedProperties.includes(property.id)}
                          onChange={() => handlePropertyToggle(property)}
                        />
                        {`${property.name} (${property.property_type})`}
                      </label>
                    ))}
                  </div>
                ) : (
                  <p>No properties available for the selected customers.</p>
                )}
              </div>

              {/* Display Selected Properties */}
              {selectedProperties.length > 0 && (
                <div className="selected-items">
                  <h4>Selected Properties:</h4>
                  <ul>
                    {availableProperties
                      .filter((property) =>
                        selectedProperties.includes(property.id)
                      )
                      .map((property) => (
                        <li key={property.id}>
                          {property.name} ({property.property_type})
                        </li>
                      ))}
                  </ul>
                </div>
              )}
            </>
          )}

          {/* Submit Button */}
          {!isSignupSuccessful && (
            <button
              type="submit"
              className="create-user-button"
              disabled={isSaving}
            >
              {isSaving ? "Creating..." : "Create User"}
            </button>
          )}
        </form>

        {/* Success Overlay and Done Button */}
        {isSignupSuccessful && (
          <>
            <div className="form-overlay"></div>
            <button className="done-button" onClick={onClose}>
              Done
            </button>
          </>
        )}

        {/* Success Popup */}
        {showSuccessPopup && (
          <div className="success-popup">
            <p>{success}</p>
          </div>
        )}

        {/* Close Button */}
        <button className="close-button" onClick={onClose}>
          ×
        </button>
      </div>
    </div>
  );
};

export default SignupForm;
