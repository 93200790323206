import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { parseJwt } from '../util/parseJwt';
import logo from '../assets/images/dynamiq-logo.png';
import Footer from './Footer';
import './LoginPage.css';

const LoginPage = ({ handleLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/login/`, {
        email,
        password
      });

      const { token } = response.data;
      localStorage.setItem('token', token);
      const decoded = parseJwt(token);
      const isAdmin = decoded.email.endsWith('@totalcomfortsolutions.com');
      localStorage.setItem('isAdmin', isAdmin);
      handleLogin();
      navigate('/home');
    } catch (err) {
      setError('Invalid email or password');
      console.error('Login error:', err);
    }
  };

  return (
    <div className="center-container">
      <div className="logo-container">
        <img src={logo} alt="TCS Logo" />
      </div>
      <div className="login-container">
        <h1>Log In</h1>
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleSubmit} className="form-container">
          <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
          <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
          <button type="submit">Log in</button>
        </form>
        <Link to="/forgot-password" className="forgot-password-link">Forgot Credentials?</Link>
      </div>
      <Footer />
    </div>
  );
};

export default LoginPage;
