import React, { useState, useEffect } from "react";
import "./RemoveUserForm.css";

const RemoveUserForm = ({ onClose }) => {
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [success, setSuccess] = useState("");
  const [isRemoveSuccessful, setIsRemoveSuccessful] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    // Fetch all users from /api/users
    fetch(`/api/users`)
      .then((response) => response.json())
      .then((data) => {
        setUsers(data.users || []);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
        setUsers([]);
      });
  }, []);

  useEffect(() => {
    console.log("Selected Users Updated: ", selectedUsers);
  }, [selectedUsers]);

  function toProperCase(text) {
    return text.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const handleUserChange = (userEmail) => {
    setSelectedUsers((prevSelected) => {
      const newSelected = prevSelected.includes(userEmail)
        ? prevSelected.filter((EMAIL) => EMAIL !== userEmail)
        : [...prevSelected, userEmail];
      console.log("After Update: ", newSelected);
      return newSelected;
    });
  };

  const handleRemoveUsers = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    if (selectedUsers.length === 0) {
      console.log("No users selected.");
      return;
    }

    try {
      const response = await fetch("/api/users/remove-users", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          users: selectedUsers,
        }),
      });
      setSuccess("Users Removed successfully!");
      setError("");
      setIsRemoveSuccessful(true);
      setShowSuccessPopup(true);

      if (response.ok) {
        const result = await response.json();
        console.log("Users removed successfully:", result);
        // Refresh users after removal
        setUsers(users.filter((user) => !selectedUsers.includes(user.EMAIL)));
        setSelectedUsers([]);
      } else {
        console.error("Failed to remove users:", response.statusText);
      }
    } catch (error) {
      console.error("Error removing users:", error);
    }
  };

  // Filter users based on search text and sort alphabetically by name
  const filteredUsers = users
    .filter(
      (user) =>
        user.NAME && user.NAME.toLowerCase().includes(searchText.toLowerCase())
    )
    .sort((a, b) => a.NAME.localeCompare(b.NAME)); // Sort alphabetically by name

  return (
    <div className="overlay">
      <div className="remove-user-form-modal">
        <h2>Remove Users</h2>
        {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p>}
        <form onSubmit={handleRemoveUsers}>
          <div className="search-container">
            <input
              type="text"
              placeholder="Search Users"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className="search-input"
            />
          </div>

          <div className="users-section">
            <div className="users-checkboxes">
              {filteredUsers.map((user) => (
                <label key={user.EMAIL} className="user-checkbox">
                  <input
                    type="checkbox"
                    value={user.EMAIL}
                    checked={selectedUsers.includes(user.EMAIL)}
                    onChange={() => handleUserChange(user.EMAIL)}
                  />
                  <div>
                    <span className="bold">{toProperCase(user.NAME)}</span> (
                    {user.EMAIL})
                  </div>
                </label>
              ))}
            </div>
          </div>

          {!isRemoveSuccessful && <button type="submit">Remove Users</button>}
        </form>

        {isRemoveSuccessful && (
          <>
            <div className="form-overlay"></div>
            <button className="done-button" onClick={onClose}>
              Done
            </button>
          </>
        )}

        {showSuccessPopup && (
          <div className="success-popup">
            <p>{success}</p>
          </div>
        )}
        <button className="close-button" onClick={onClose}>
          ×
        </button>
      </div>
    </div>
  );
};

export default RemoveUserForm;
