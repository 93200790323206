import React, { useState, useEffect, useRef } from "react";
import "./EditUserForm.css";
import RemoveUserForm from "./RemoveUserForm";

const LoadingSpinner = () => <div className="loading-spinner">Loading...</div>;

const EditUserForm = ({ onClose }) => {
  const [users, setUsers] = useState([]); // List of all users
  const [selectedUser, setSelectedUser] = useState(null); // Currently selected user
  const [customers, setCustomers] = useState([]); // List of all customers
  const [selectedCustomers, setSelectedCustomers] = useState([]); // IDs of selected customers
  const [properties, setProperties] = useState([]); // List of properties based on selected customers
  const [selectedProperties, setSelectedProperties] = useState([]); // IDs of selected properties
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [isUpdateSuccessful, setIsUpdateSuccessful] = useState(false);
  const [showRemoveUserForm, setShowRemoveUserForm] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [customerSearchQuery, setCustomerSearchQuery] = useState("");
  const [propertySearchQuery, setPropertySearchQuery] = useState("");
  const dropdownRef = useRef(null);

  // Loading state variables
  const [isUsersLoading, setIsUsersLoading] = useState(false);
  const [isCustomersLoading, setIsCustomersLoading] = useState(false);
  const [isPropertiesLoading, setIsPropertiesLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false); // Optional: Loading state for saving changes

  // Fetch all users on component mount
  useEffect(() => {
    const fetchUsers = async () => {
      const token = localStorage.getItem("token");
      setIsUsersLoading(true); // Start loading
      try {
        const response = await fetch("/api/users/", {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.ok) {
          const data = await response.json();
          setUsers(data.users || []);
        } else {
          throw new Error(`Error fetching users: ${response.statusText}`);
        }
      } catch (err) {
        console.error(err);
        setError("Failed to fetch users.");
        setUsers([]); // Default to empty array
      } finally {
        setIsUsersLoading(false); // Stop loading
      }
    };

    fetchUsers();
  }, []);

  const filteredUsers = users.filter((user) =>
    user.EMAIL.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Fetch all customers
  const fetchAllCustomers = async () => {
    const token = localStorage.getItem("token");
    setIsCustomersLoading(true); // Start loading
    console.log("Fetching all customers...");
    try {
      const response = await fetch("/api/users/customers/", {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.ok) {
        const data = await response.json();
        console.log("Customers fetched:", data.users);
        setCustomers(data.users || []);
      } else {
        throw new Error(`Error fetching customers: ${response.statusText}`);
      }
    } catch (err) {
      console.error(err);
      setError("Failed to fetch customers.");
      setCustomers([]); // Default to empty array
    } finally {
      setIsCustomersLoading(false); // Stop loading
      console.log("Finished fetching customers.");
    }
  };

  // Fetch properties based on selected customers
  const fetchPropertiesForCustomers = async (customerIds) => {
    const token = localStorage.getItem("token");
    setIsPropertiesLoading(true); // Start loading
    console.log("Fetching properties for customer IDs:", customerIds);
    try {
      const response = await fetch("/api/users/customers/properties", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ customerIds }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Properties fetched:", data.properties);
        setProperties(data.properties || []);
      } else {
        throw new Error(`Error fetching properties: ${response.statusText}`);
      }
    } catch (err) {
      console.error(err);
      setError("Failed to fetch properties.");
      setProperties([]); // Default to empty array
    } finally {
      setIsPropertiesLoading(false); // Stop loading
      console.log("Finished fetching properties.");
    }
  };

  // Handle user selection
  const handleUserChange = async (email) => {
    const token = localStorage.getItem("token");
    const user = users.find((u) => u.EMAIL === email);
    console.log(`User selected: ${email}`, user);
    setSelectedUser(user);
    setSelectedCustomers([]); // Reset selected customers
    setSelectedProperties([]); // Reset selected properties
    setProperties([]); // Reset properties

    // Fetch all customers
    await fetchAllCustomers();

    // Fetch user's assigned customers
    try {
      console.log(`Fetching assigned customers for user: ${email}`);
      const response = await fetch(
        `/api/users/getcustomerbyemail/${encodeURIComponent(email)}`,
        {
          method: "GET", // Use GET to fetch assigned customers
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.ok) {
        const data = await response.json();
        console.log("Assigned customers data:", data.customers);

        // Extract customer IDs correctly, since BUILDOPS_CUSTOMER_ID is an array
        const customerIds =
          data.customers?.flatMap((c) => c.BUILDOPS_CUSTOMER_ID) || [];
        console.log("Assigned customer IDs:", customerIds);
        setSelectedCustomers(customerIds); // Set selected customers

        // Fetch properties associated with selected customers
        await fetchPropertiesForCustomers(customerIds);

        // Fetch user's assigned properties
        console.log(`Fetching assigned properties for user: ${email}`);
        const propResponse = await fetch(
          `/api/users/getproperty/${encodeURIComponent(email)}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (propResponse.ok) {
          const propData = await propResponse.json();
          console.log("Assigned properties data:", propData);

          // Check if propData.user exists and has a PROPERTIES field
          const propertyNames = propData.user[0]?.PROPERTIES || [];
          const propertyIds = propData.user[0]?.BUILDOPS_PROPERTY_IDS || [];
          const properties = propertyNames.map((name, index) => ({
            ID: propertyIds[index],
            NAME: name,
          }));
          setSelectedProperties(properties); // Store both names and IDs together
        } else {
          throw new Error(
            `Error fetching user properties: ${propResponse.statusText}`
          );
        }
      } else {
        throw new Error(
          `Error fetching user customers: ${response.statusText}`
        );
      }
    } catch (err) {
      console.error(err);
      setError("Failed to fetch user assigned customers or properties.");
    }
  };

  // Handle customer selection
  const handleCustomerChange = (customerId) => {
    setSelectedCustomers((prev) => {
      const isSelected = prev.includes(customerId);
      console.log(
        `Customer ${isSelected ? "deselected" : "selected"}: ID ${customerId}`
      );
      if (isSelected) {
        return prev.filter((id) => id !== customerId);
      } else {
        return [...prev, customerId];
      }
    });
  };

  // Effect to fetch properties whenever selectedCustomers changes
  useEffect(() => {
    if (selectedCustomers.length > 0) {
      console.log("Selected customers changed. Fetching properties...");
      fetchPropertiesForCustomers(selectedCustomers);
    } else {
      console.log("No customers selected. Clearing properties.");
      setProperties([]);
      setSelectedProperties([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomers]);

  // Handle property selection
  // Handle property selection
  const handlePropertyChange = (property) => {
    setSelectedProperties((prevSelected) => {
      const isSelected = prevSelected.some(
        (selectedProp) => selectedProp.ID === property.ID
      );
      if (isSelected) {
        // Deselect the property by filtering it out
        return prevSelected.filter(
          (selectedProp) => selectedProp.ID !== property.ID
        );
      } else {
        // Select the property, storing both name and id
        return [...prevSelected, { ID: property.ID, NAME: property.NAME }];
      }
    });
  };

  // Handle form submission
  const handleSaveChanges = async (e) => {
    e.preventDefault();

    // Ensure a user is selected
    if (!selectedUser) {
      setError("No user selected.");
      console.log("Form submission failed: No user selected.");
      return;
    }

    const token = localStorage.getItem("token");
    const email = selectedUser.EMAIL; // Get the email of the selected user

    const selectedCustomerData = selectedCustomers.map((customerId) => {
      const customer = customers.find((c) => c.ID === customerId); // Find the customer by ID
      return { ID: customerId, NAME: customer?.NAME || "Unnamed Customer" }; // Return both ID and NAME with fallback
    });

    console.log("Selected customers to save:", selectedCustomerData);

    try {
      setIsSaving(true); // Start saving
      console.log("Saving changes...");

      // Update Customers
      const customerResponse = await fetch(
        `/api/users/${encodeURIComponent(email)}/customers`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ customers: selectedCustomerData }), // Pass both IDs and names
        }
      );

      // Check if the customer update request was successful
      if (!customerResponse.ok) {
        throw new Error(
          `Failed to update customers: ${customerResponse.statusText}`
        );
      }
      console.log("Customers updated successfully.");
      console.log("selectedproperties: ", selectedProperties);

      // Update Properties
      const propertyResponse = await fetch(
        `/api/users/${encodeURIComponent(email)}/properties`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ properties: selectedProperties }), // Pass both IDs and names
        }
      );

      // Check if the property update request was successful
      if (!propertyResponse.ok) {
        throw new Error(
          `Failed to update properties: ${propertyResponse.statusText}`
        );
      }
      console.log("Properties updated successfully.");

      // Both requests succeeded
      setSuccess("Customers and Properties updated successfully!");
      setError("");
      setShowSuccessPopup(true);
      setIsUpdateSuccessful(true);

      // Hide success message after 3 seconds
      setTimeout(() => setShowSuccessPopup(false), 3000);
      console.log("Changes saved successfully.");
    } catch (err) {
      console.error(err);
      setError("Failed to update customers or properties.");
      setIsUpdateSuccessful(false);
      console.log("Form submission failed:", err.message);
    } finally {
      setIsSaving(false); // Stop saving
      console.log("Finished saving changes.");
    }
  };

  // Handle clicking outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
        console.log("Dropdown closed by clicking outside.");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Utility function to format text to Proper Case
  const toProperCase = (text) => {
    if (typeof text !== "string") {
      console.error("Invalid input: Expected a string");
      return "";
    }
    return text.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  };

  // RemoveUserForm close handler
  const handleRemoveFormClose = () => {
    setShowRemoveUserForm(false);
    console.log("RemoveUserForm closed.");
  };

  // Handle Select All/Deselect All
  const handleSelectAllChange = () => {
    if (selectedProperties.length === properties.length) {
      // If all properties are selected, deselect all
      setSelectedProperties([]);
    } else {
      // Select all properties
      const allProperties = properties.map((property) => ({
        ID: property.ID,
        NAME: property.NAME,
      }));
      setSelectedProperties(allProperties);
    }
  };

  return (
    <div className="overlay">
      <div
        className={`edit-user-form-modal ${
          dropdownOpen ? "dropdown-open" : ""
        }`}
      >
        <button
          className="add-user-button"
          onClick={() => {
            setShowRemoveUserForm(true);
            console.log("Show RemoveUserForm triggered.");
          }}
        >
          Remove User Form
        </button>
        {showRemoveUserForm && (
          <RemoveUserForm onClose={handleRemoveFormClose} />
        )}
        <h2>Edit User Customers & Properties</h2>
        {error && <p className="error">{error}</p>}
        {success && <p className="success">{success}</p>}
        <form onSubmit={handleSaveChanges}>
          {/* User Selection Dropdown */}
          <div className="dropdown-container" ref={dropdownRef}>
            <input
              type="text"
              placeholder="Select User"
              onClick={() => {
                setDropdownOpen(!dropdownOpen);
                console.log("User dropdown toggled:", !dropdownOpen);
              }}
              value={searchQuery} // Use searchQuery for value to combine both display and search
              onChange={(e) => {
                setSearchQuery(e.target.value);
                console.log("User search query updated:", e.target.value);
              }} // Handle both search and display
              className="dropdown-input"
            />
            {dropdownOpen && (
              <div className="dropdown-menu">
                {isUsersLoading ? (
                  <LoadingSpinner />
                ) : filteredUsers.length > 0 ? (
                  filteredUsers
                    .sort((a, b) => a.EMAIL.localeCompare(b.EMAIL))
                    .map((user) => (
                      <div
                        key={user.EMAIL}
                        className="dropdown-item"
                        onClick={() => {
                          handleUserChange(user.EMAIL);
                          setDropdownOpen(false);
                          setSearchQuery(""); // Reset search query after selection
                          console.log(
                            `User selected from dropdown: ${user.EMAIL}`
                          );
                        }}
                      >
                        {user.EMAIL}
                      </div>
                    ))
                ) : (
                  <div className="no-results">No users found</div> // Handle no results case
                )}
              </div>
            )}
          </div>

          {/* Customers and Properties Selection */}
          <div className="customers-properties-container">
            {selectedUser && (
              <>
                {/* Customers Section */}
                <div>
                  <div className="customers-section-edit">
                    <h3>Select Customers</h3>
                    {/* Search input to filter customers */}
                    <input
                      type="text"
                      placeholder="Search customers..."
                      value={customerSearchQuery}
                      onChange={(e) => {
                        setCustomerSearchQuery(e.target.value);
                        console.log(
                          "Customer search query updated:",
                          e.target.value
                        );
                      }} // Update search query
                      className="customer-search-input"
                    />

                    {isCustomersLoading ? (
                      <LoadingSpinner />
                    ) : (
                      (() => {
                        // Filter customers based on search query
                        const filteredCustomers = customers.filter((customer) =>
                          customer.NAME.toLowerCase().includes(
                            customerSearchQuery.toLowerCase()
                          )
                        );

                        if (filteredCustomers.length > 0) {
                          return (
                            <div className="properties-list">
                              {filteredCustomers
                                .sort((a, b) => a.NAME.localeCompare(b.NAME))
                                .map((customer) => {
                                  // Extract customerId correctly
                                  const customerId = customer.ID;

                                  return (
                                    <label
                                      key={customerId}
                                      className="property-checkbox"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={selectedCustomers.includes(
                                          customerId
                                        )} // Pre-select based on selectedCustomers
                                        onChange={() =>
                                          handleCustomerChange(customerId)
                                        }
                                      />
                                      {customer.NAME || "Unnamed Customer"}
                                    </label>
                                  );
                                })}
                            </div>
                          );
                        } else {
                          return <p>No customers available.</p>;
                        }
                      })()
                    )}
                  </div>

                  {/* Display Selected Customers */}
                  {selectedCustomers.length > 0 && (
                    <div className="selected-items">
                      <h4>Selected Customers:</h4>
                      <ul>
                        {customers
                          .filter((customer) =>
                            selectedCustomers.includes(customer.ID)
                          )
                          .map((customer) => (
                            <li key={customer.ID}>
                              {customer.NAME}
                              <button
                                type="button"
                                className="remove-button"
                                onClick={() => {
                                  handleCustomerChange(customer.ID);
                                  console.log(
                                    `Customer removed: ID ${customer.ID}`
                                  );
                                }}
                              >
                                ×
                              </button>
                            </li>
                          ))}
                      </ul>
                    </div>
                  )}
                </div>

                {/* Properties Selection */}
                <div>
                  {/* Properties Selection */}
                  <div className="properties-section-edit">
                    <h3>Select Properties</h3>

                    {/* Search input to filter properties */}
                    <input
                      type="text"
                      placeholder="Search properties..."
                      value={propertySearchQuery}
                      onChange={(e) => {
                        setPropertySearchQuery(e.target.value);
                        console.log(
                          "Property search query updated:",
                          e.target.value
                        );
                      }}
                      className="property-search-input"
                    />

                    {isPropertiesLoading ? (
                      <LoadingSpinner />
                    ) : properties.length > 0 ? (
                      (() => {
                        // Filter properties based on search query
                        console.log(properties);
                        const filteredProperties = properties.filter(
                          (property) =>
                            (property.NAME &&
                              property.NAME.toLowerCase().includes(
                                propertySearchQuery.toLowerCase()
                              )) ||
                            (property.PROPERTY_TYPE &&
                              property.PROPERTY_TYPE.toLowerCase().includes(
                                propertySearchQuery.toLowerCase()
                              ))
                        );

                        if (filteredProperties.length > 0) {
                          return (
                            <div className="properties-list">
                              {/* Select All Checkbox */}
                              <label className="property-checkbox">
                                <input
                                  type="checkbox"
                                  checked={
                                    selectedProperties.length ===
                                      filteredProperties.length &&
                                    filteredProperties.length > 0
                                  }
                                  onChange={handleSelectAllChange} // Handles select all or deselect all logic
                                />
                                {selectedProperties.length ===
                                filteredProperties.length
                                  ? "Select None" // If all are selected, show this label
                                  : "Select All"}{" "}
                                {/* If not all are selected, show this */}
                              </label>
                              {filteredProperties
                                .sort((a, b) => a.NAME.localeCompare(b.NAME)) // Sort properties alphabetically by name
                                .map((property) => {
                                  const isSelected = selectedProperties.some(
                                    (selectedProp) =>
                                      selectedProp.ID === property.ID
                                  );
                                  return (
                                    <label
                                      key={property.ID}
                                      className="property-checkbox"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={isSelected}
                                        onChange={() =>
                                          handlePropertyChange(property)
                                        } // Pass the entire property object
                                      />
                                      {property.NAME}
                                    </label>
                                  );
                                })}
                            </div>
                          );
                        } else {
                          return <p>No properties match your search.</p>;
                        }
                      })()
                    ) : (
                      <p>No properties available.</p>
                    )}
                  </div>
                  {/* Display Selected Properties */}
                  {selectedProperties.length > 0 && (
                    <div className="selected-items">
                      <h4>Selected Properties:</h4>
                      <ul>
                        {selectedProperties.map((property) => (
                          <li key={property.ID}>
                            {property.NAME}
                            <button
                              type="button"
                              className="remove-button"
                              onClick={() => handlePropertyChange(property)} // Pass the entire property object for deselection
                            >
                              ×
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>

          {/* Save Changes Button */}
          <button type="submit" className="save-button" disabled={isSaving}>
            {isSaving ? "Saving..." : "Save Changes"}
          </button>
        </form>

        {/* Success Popup */}
        {showSuccessPopup && (
          <div className="success-popup">
            <p>{success}</p>
          </div>
        )}

        {/* Done Button */}
        {isUpdateSuccessful && (
          <>
            <div className="form-overlay"></div>
            <button className="done-button" onClick={onClose}>
              Done
            </button>
          </>
        )}

        {/* Close Button */}
        <button className="close-button" onClick={onClose}>
          ×
        </button>
      </div>
    </div>
  );
};

export default EditUserForm;
